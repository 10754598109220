<template>
  <v-main>
    <nav-bar />
    <template slot:top>
      <v-subheader>
        <h1>Candidatures</h1>
      </v-subheader>
      <v-toolbar flat>
        <v-text-field
          class="pr-2 flex-grow-1"
          v-model="query"
          label="Mot clé"
          @input="search"
        />
        <v-select
          v-model="searchField"
          :items="searchFields"
          class="pl-2 flex-grow-0"
          dense
          solo
          @change="search"
        />
      </v-toolbar>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="itemsLength"
      :options.sync="options"
      :must-sort="true"
      :footer-props="{ 'items-per-page-options': [50] }"
      dense
    >
      <template v-slot:item.renter="{ item }">
        <v-btn small class="info" @click="editRenter(item.renterUid)">
          {{ item.renter }}
        </v-btn>
      </template>
      <template v-slot:item.property="{ item }">
        <v-btn small class="info" @click="editProperty(item.propertyUid)">
          {{ item.property }}
        </v-btn>
      </template>
      
    <template v-slot:item.rentalFile="{ item }">
      <v-btn
        small
        class="info"
        @click="editRentalFile(item.rentalFileUid)"
         
      >
       {{item.rentalFile}}
      </v-btn>
    </template>

    <!-- <template v-slot:item.booking="{ item }">
      <v-btn
        v-if="item.booking"
        small
        class="info"
        @click="editBooking(item.bookingUid)" 
      >
       {{item.booking}}
      </v-btn>
    </template> -->

    <template v-slot:item.status="{ item }">
        
        <p>
          {{ getStatus(item) }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" target="_blank" @click="editItem(item.uid)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-main>
</template>

<script>
import axios from "axios";
import NavBar from "../components/navBar.vue";

export default {
  components: {
    NavBar,
  },
  data: () => ({
    headers: [
      { text: "Id", value: "id" },
      { text: "Dossier", value: "rentalFile" },
      { text: "Locataire", value: "renter" },
      { text: "Logement", value: "propertyId", sortable: false },
      { text: "Propriétaire", value: "ownerId", sortable: false },
      // { text: "Réservation", value: "booking"},
      { text:"ReceiverSponsor", value: "receiverSponsor" },
      { text: "Date de la candidature", value: "creationDate" },
      { text: "Date de la notation", value: "scoringDate" },
      { text: "Status", value: "status" },
      { text: "Note", value: "score" },
      { text: "Action", value: "actions", sortable: false },
    ],
    searchFields: [
      {text: "Dans toutes les colonnes", value: "id,rentalFile,renter,property,creationDate,scoringDate,status,score"},
      { text: "Id", value: "id" },
      { text: "Dossier", value: "rentalFile" },
      { text: "Locataire", value: "renter" },
      // { text: "Logement", value: "propertyId" },
      // { text: "Propriétaire", value: "ownerId"},
      { text: "Date de la candidature", value: "creationDate" },
      { text: "Date de la notation", value: "scoringDate" },
      { text: "Statuts", value: "status" },
      { text: "Note", value: "score" },
      { text:"ReceiverSponsor", value: "receiverSponsor" },
    ],
    searchField: "id,rentalFile,renter,property,receiverSponsor,creationDate,scoringDate,status,score",
    items: [],
    itemsLength: 0,
    options: {
      sortBy: ["id"],
      sortDesc: [true],
    },
    status: [
      {
        value :1, text: '1 - Crée'
      },
      {
        value :2, text: '2 - Refusé'
      },
      {
        value :3, text: '3 - Candidat accepté'
      },
      {
        value :4, text: '4 - Frais payés'
      },
      {
        value :5, text: '5 - Annulé'
      },
      {
        value :6, text: '6 - Locataire en place'
      },
    ],
    query: "",
  }),

  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },

  methods: {
    getStatus(item){
      return this.status.find( status => status.value === item.status).text;
    },
    getItems() {
      axios
        .get(process.env.VUE_APP_APIURL + "/applications", {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            limit: this.options.itemsPerPage,
            sort:
              this.options.sortBy[0] +
              " " +
              (this.options.sortDesc[0] ? "DESC" : "ASC"),
            searchQuery: this.query !== "" ? this.query : undefined,
            searchFields: this.searchField,
            expand: "property",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.items = response.data.map((item) =>
            Object.assign(
              item,
              { creationDate: new Date(item.creationDate).toLocaleString() },
              { scoringDate: item.scoringDate != null ? new Date(item.scoringDate).toLocaleString() : '' },
              { status: item.status},
              { propertyId: item.property?.id },
              { ownerId: item.property?.owner.id},
              // { score: item.score != null ? `${item.score.id} ${item.score.label}` : '' }
            )
          );
        });
      axios
        .get(process.env.VUE_APP_APIURL + "/applications", {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            count: 1,
            searchQuery: this.query !== "" ? this.query : undefined,
            searchFields: this.searchField,
          },
        })
        .then((response) => {
          this.itemsLength = eval(response.data[0].count);
        });
    },

    search() {
      this.options.page = 1;
      this.getItems();
    },

    editItem(uid) {
      open(
        this.$router.resolve({
          name: "application",
          params: { applicationUid: uid },
        }).href,
        "_blank"
      );
    },

    editRenter(uid) {
      open(
        this.$router.resolve({ name: "user", params: { userUid: uid } }).href,
        "_blank"
      );
    },

    // editBooking(uid){
    //   open(
    //     this.$router.resolve({ name: "booking", params: { bookingUid: uid } }).href,
    //     "_blank"
    //   );
    // },

    editRentalFile (uid) {
        open(this.$router.resolve({name: 'rentalFile', params: {rentalFileUid: uid}}).href, '_blank');
      },

    editProperty(uid) {
      open(
        this.$router.resolve({ name: "property", params: { propertyUid: uid } })
          .href,
        "_blank"
      );
    },
  },
};
</script>
